import axios from "axios";
// import errorCode from '../../utils/errorCode'
// import Message from './message.js'
// import { Toast } from 'primereact/toast';
// import {render} from "react-dom";
// import { createRoot } from 'react-dom/client'
// import {getToken} from "../../utils/getToken";
// import {Navigate} from "react-router-dom";
// import { App } from 'antd';
// import env from "react-dotenv";
//
// const env = process.env.HEAD_ENV;
// const publicUrl = process.env.PUBLIC_URL;

console.log(process.env.REACT_APP_API_PATH)

// 是否显示重新登录
export let expire = false;
// export let url = '20.163.229.170:2048/'
export let url = '20.163.229.170:6480/'
// export let url = 'localhost:2048/'
// 创建axios实例
let service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'http://localhost:2048/',
  baseURL: process.env.REACT_APP_API_PATH,
  // 超时
  timeout: 30000
})

// service.interceptors.response.use(res => {
//   if (!getToken()) {
//     expire = true
//   }
//   return res
// })

// 响应拦截器
service.interceptors.response.use(async res => {
    // const {message} = App.useApp();
    const code = res.data.code || 200;
    console.log(code)
    // if (!getToken()) {
    //   expire = true
    // }
    return res
    // const [api, contextHolder] = notification.useNotification();
    //   // 未设置状态码则默认成功状态
    //   // 获取错误信息
    //   let msg = errorCode[code] || res.data.msg || ((code == 200) ? errorCode['default200'] : errorCode['default'])
    //   if (msg.includes('DB Error') || msg.includes('System Error')) {
    //     msg = errorCode['default']
    //   }
    //   // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res
    }
    if (code === 401) {
      // api['warning']({
      //   message: 'Login Expired',
      //   description: ''
      // })
      // alert('a')
      return Promise.reject(new Error('error'))
    } else if (code === 500) {
      // api['error']({
      //   message: 'Request Failed',
      //   description: ''
      // })
      // alert('b')
      return Promise.resolve(new Error('error'))
    } else {
      // if (msg !== 'SUCCESS' && msg !== '查询成功') {
      //   api['success']({
      //     message: 'Success',
      //     description: '',
      //   })
      // }
      // alert('c')
      // await message.success('Success!');
      return Promise.resolve(res)
    }
  },
  error => {
    //   console.log('err:' + error)
    //   let { message } = error;
    //   if (message === "Network Error") {
    //     message = 'Connection Exception';
    //   }
    //   else if (message.includes("timeout")) {
    //     message = 'System request ' + error.config.url + ' timeout';
    //   }
    //   else if (message.includes("Request failed with status code")) {
    //     message = 'System interface ' + message.substr(message.length - 3) + ' exception';
    //   }
    //   // toast.current.show({ severity: 'warn', summary: 'Error', detail: message, life: 3000 })
      return Promise.reject(new Error(error))
  }
)

export default service

// //添加请求拦截器
// axios.interceptors.request.use(
//   config => {
//     //加载动画
//     openLoading()
//     if (localStorage.sessionId) { //如果我的sessionId存在(sessionId其实就是token)
//       // 设置统一的请求header
//       config.headers.Authorization = localStorage.sessionId //授权（每次请求把sessionId带给后台）
//     }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

// //添加响应拦截器
// axios.interceptors.response.use(
//   response => {　　//逻辑处理
//     if (response.data.errorCode === -1) { //后端反回的状态值，本应该errorCode是status
//       Message({
//         type: 'warning',
//         duration: 1500,
//         showClose: true,
//         message: '当前登录已失效，请从新登录！'
//       })
//       router.push("/logon")//让用户从新回到登录页面
//     }
//     return response;
//   },
//   error => {
//     closeLoading() //请求超时清除加载动画
//     return Promise.resolve(error.response);
//   }
// );
//
//
// axios.defaults.baseURL = "http://127.26.41.181:8081/nei";    // 开发
//
// axios.defaults.headers.post["Content-Type"] = "application/json";
// // axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.timeout = 6000; //设置请求超时时间
//
// function checkStatus(response) {
//   return new Promise((resolve, reject) => {
//     if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
//       try {
//         closeLoading() //关闭加载动画
//       } catch (e) {
//         //抛出异常
//         console.log(e)
//       }
//       resolve(response.data);
//     } else {
//       try {
//         closeLoading() //关闭加载动画
//       } catch (e) {
//         //抛出异常
//         console.log(e)
//       }
//       Message({
//         type: 'error',
//         showClose: true,
//         message: '网络异常，请检查网络连接是否正常！'
//       })
//     }
//   });
// }
//
// export default {
//   post(url, params) {
//     return axios({
//       method: "post",
//       url,
//       data: params
//     }).then(response => {
//       return checkStatus(response);
//     });
//   },
//   get(url, params) {
//     params = qs.stringify(params);
//     return axios({
//       method: "get",
//       url,
//       params
//     }).then(response => {
//       return checkStatus(response);
//     });
//   }
// };
