import {useEffect, useState} from "react";
import {sendOrderToOMS} from "../../Api/Order/order";
import './Table.scss'
import logo from "../../Assets/img/logo_white.png";
import Header from "./Header"
import {
  PullRefresh,
  Tabs,
  Image,
  Divider,
  Toast,
  Popup,
  Badge,
  Form,
  Selector,
  Button,
  Stepper,
  Overlay, Dialog
} from 'react-vant'
import {getAddOnByMaterial, getCategory, getMaterialWithCategory, getTableById} from "../../Api/Menu/menu";
import CartButton from "./Cart";
import {PullToRefresh, Cell, Loading} from '@nutui/nutui-react';
import {Cart, Close, Fail, Warning, WarnO} from "@react-vant/icons";

function Table() {
  const [cartDataList, setCartDataList] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [tableInfo, setTableInfo] = useState({id: '', name: ''})
  const [value, setValue] = useState(0);
  const [category, setCategory] = useState([])
  const [materialList, setMaterialList] = useState([])
  // const [productCode, setProductCode] = useState('')
  const [showDetail, setShowDetail] = useState({display: false, productInfo: {}})
  const [addOnList, setAddOnList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [visible, setVisible] = useState(false)
  const [showError, setShowError] = useState(false)
  const [subtotal, setSubtotal] = useState(0)
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false)
  const [addLoading, setAddLoading] = useState(true)

  useEffect(()=>{
    initData()
  },[])

  useEffect(() => {
    if (showDetail.display) {
      window.history.pushState(null, '', document.URL)
      window.onpopstate = function (e) {
        closeShowDetail()
      }
      return () => {
        window.onpopstate = null;
      };
    }
  }, [showDetail.display]);

  const initData = async () => {
    const searchURL = window.location.search;
    const params = new URLSearchParams(searchURL);
    const table = params.get('table')
    if ( table === null || table === undefined) {
    //  提示网址有问题
      setShowError(true)
    } else {
      // setTableId(table)
      await getTableInfo(table)
      await getMenuCategory()
    }
  }

  const getTableInfo = async (id) => {
    try {
      const res = await getTableById({tableId: id})
      setTableInfo({id: id,name: res.data.data.tableName})
    } catch (e) {
      console.log(e)
    }
  }

  const getMenuCategory = async () => {
    try {
      setIsLoading(true)
      let orgCode = localStorage.getItem("organizationCode")
      const menuData = await getCategory({organizationCode: orgCode})
      setCategory(menuData.data.data.data)
      const res = await getMaterialWithCategory({organizationCode: orgCode})
      setMaterialList(res.data.data)
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }

  const placeOrder = async (data) => {
    setPlaceOrderLoading(true)
    let orderData = JSON.parse(JSON.stringify(data))
    // console.log(subtotal)
    try {
      let order = {orderHeader: {}, orderItems: []}
      order.orderHeader = {
        createBy: 'Customer',
        currency: orderData[0].currency,
        orderNote: '',
        orderType: 0,
        organizationCode: localStorage.getItem("organizationCode"),
        tableId: tableInfo.id,
        subtotal: Number(subtotal),
        tax: Number(subtotal) * 1.13,
        total: Number(subtotal) + Number(subtotal) * 0.13,
      }
      orderData.forEach(item => {
        item.selections.forEach(sel => {
          delete sel.value
          delete sel.label
        })
      })
      order.orderItems = orderData
      const res = await sendOrderToOMS(order)
      if (res.data.data === "Success") {
        setVisible(false)
        setSubtotal(0)
        setCartDataList([])
      } else {
        await Dialog.alert({
          message:(
            <>
              <div>
                <Warning  fontSize={'2rem'} color={"red"}/>
              </div>
              <div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                Order placement failed, please try again
              </div>
            </>
          ),
          confirmButtonText: 'Back'
        })
      }
    }
    catch (e) {
      console.log(e)
    }
    setPlaceOrderLoading(false)
  }

  const onRefresh =  (showToast) => {
    return new Promise(async resolve => {
      try {
        await initData()
        if (showToast) {
          Toast.info('Success')
        }
        resolve(true)
      } catch (e) {

      }
    })
  }

  const showMaterialDetail = async (product) => {
    try {
      setAddLoading(true)
      const res = await getAddOnByMaterial({
        organizationCode: localStorage.getItem("organizationCode"),
        materialId: product.materialId
      })
      // console.log(res.data.data)
      let data = []
      res.data.data.forEach(header => {
        header.materialItems.forEach(item => {
          const price = (item.itemBasePrice >= 0) ? '+' + item.itemBasePrice.toFixed(2) : item.itemBasePrice.toFixed(2)
          Object.assign(item, {label: item.itemName + ' ('+ price +')', value: item.materialItemId})
        })
        data.push({...header, selections: []})
      })
      calcTotal(product, data)
      setAddOnList(data)
      setAddLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const closeShowDetail = () => {
    setShowDetail({display: false, productInfo: {}})
    setAddOnList([])
    setPrice(0)
    setQuantity(1)
  }

  const changeSelection = (arr, extend, headerData, addOnList, index) => {
    // console.log(arr)
    // console.log(extend.items)
    // console.log(headerData)
    // console.log(addOnList)
    // console.log(index)
    // let selections = []
    // arr.forEach(addOnId => {
    //   headerData.materialItems.forEach(item => {
    //     if (addOnId === item.materialItemId){
    //       selections.push(item)
    //     }
    //   })
    // })
    addOnList.forEach(header => {
      if(header.materialHeaderId === headerData.materialHeaderId) {
        let selection = []
        arr.forEach(addOnId => {
          headerData.materialItems.forEach(item => {
            if (addOnId === item.materialItemId){
              selection.push(item)
            }
          })
        })
        header.selections = selection
      }
    })
    // console.log(showDetail.productInfo)
    // console.log(addOnList)
    calcTotal(showDetail.productInfo, addOnList)
    setAddOnList(addOnList)
  }

  const addProduct = () => {
    let products = JSON.parse(JSON.stringify(showDetail.productInfo))
    products.materialHeaders = JSON.parse(JSON.stringify(addOnList))
    // console.log(products)
    // console.log(addOn)
    let pass = false;
    // console.log(products.materialHeaders)
    for (let i = 0; i < products.materialHeaders.length; i++){
      let product = products.materialHeaders[i]
      // 如果required === 1，必选
      if (product.required === 1) {
        // 将数据统一处理为数组，如果不能处理为数组，比如null/undefine，则无法通过
        let selections = []
        if (product.selections) {
          if (product.selections.length > 0) {
            selections.push.apply(selections,product.selections)
          } else if (product.selections.hasOwnProperty('materialItemId')) {
            selections.push.apply(selections,[product.selections])
          }
        } else {
          pass = false
          break
        }
        // 此时selection必定为数组，判定选定数量大于0，且小于最大选定数
        if (selections.length === 0) {
          pass = false
          break
        }
        else if (selections.length > product.maximum) {
          pass = false
          break
        }
        else {
          pass = true
        }
      }
      // 如果只是可选，则无需多余判断
      else {
        pass = true
      }
    }
    if (products.materialHeaders.length === 0) {
      pass = true
    }
    if (pass) {
      // console.log(total)
      products.selections = []
      products.price = price
      products.note = ''
      products.quantity = quantity
      products.materialHeaders.forEach(header => {
        // console.log(header.selections)
        if (header.selections) {
          if (header.selections.length > 0) {
            products.selections.push.apply(products.selections,header.selections)
          } else if (header.selections.hasOwnProperty('materialItemId')) {
            products.selections.push.apply(products.selections,[header.selections])
          }
        }
      })
      delete products.materialHeaders
      delete products.commonHeader
      cartDataList.push(products)
      setCartDataList(cartDataList)
      closeShowDetail()
    } else {
      Toast.info('Selections Required!')
    }
  }

  const calcTotal = (products, addOnList) => {
    // console.log(products)
    let price = Number(products.basePrice)
    // console.log(total)
    addOnList.forEach(header => {
      // console.log(header)
      if (header.selections) {
        if (header.selections.length > 0) {
          header.selections.forEach(item => {
            price += Number(item.itemBasePrice)
          })
        } else if (header.selections.hasOwnProperty('itemBasePrice')) {
          price += Number(header.selections.itemBasePrice)
        }
      }
    })
    // console.log(price)
    setPrice(price)
  }

  return(
    <div style={{height: '100%', overflow: visible ? 'hidden' : 'unset'}}>
      {/* Header*/}
      <Header name={tableInfo.name}/>
      {/* Cart*/}
      <div className={"cart"}>
        <Button className={"cart-button"} round type='info'
                onClick={()=>{
                  if (cartDataList.length > 0) {setVisible(true)}
                }}
        >
          <Badge content={cartDataList.length} color="orange" max={9}>
            <Cart fontSize={'2rem'}/>
          </Badge>
        </Button>
      </div>
      <CartButton data={cartDataList}
                  updateData={(data)=>{setCartDataList(data)}}
                  visible={visible}
                  setVisible={(bool)=>{setVisible(bool)}}
                  placeOrder={async (data)=>{await placeOrder(data)}}
                  subtotal={subtotal}
                  setSubtotal={(value)=>{setSubtotal(value)}}
                  loading={placeOrderLoading}
      />
       {/*Category and Item List */}
      <div className={"item-list-container"}>
        {
          isLoading ? (
            <>
            </>
          ) : (

            <Tabs
              // stickyInitScrollbar
              sticky
              scrollspy={{ autoFocusLast: true, reachBottomThreshold: 60 }}
              offsetTop={'3rem'}
            >
              {
                category.map((cate, cIndex) => (
                  <Tabs.TabPane key={cate.categoryId} title={cate.categoryName}>
                    <div>
                      <div className={"item-list-category"}>
                        <Divider contentPosition={"center"} className={"item-list-divider"}>{cate.categoryName}</Divider>
                      </div>
                      {
                        materialList.hasOwnProperty(cate.categoryId) &&
                        materialList[cate.categoryId].map( (item, index) =>
                          (
                            <div className={"item-list"} key={cate.categoryId + '-' + index  + '-'+ item.materialId}
                                 onClick={async ()=>{
                                   // console.log(item.materialId);
                                   await setSubtotal(item.basePrice)
                                   await setShowDetail({display: true, productInfo: {...item}})
                                   await showMaterialDetail(item)}
                                 }
                            >
                              <div className={"item-list-card"}>
                                {/*<Flex.Item span={8} style={{}}>*/}
                                <Image fit={'cover'} width={50} height={50} src={item.image} round />
                                {/*</Flex.Item>*/}
                                <div className={"item-list-text"} style={{padding: '0 0.75rem', fontSize: '0.9rem', flex: 1}}>
                                  <div className={"item-list-title"}>
                                    {item.materialName}
                                  </div>
                                  <div className={"item-list-desc"}>
                                    {item.description}
                                  </div>
                                </div>
                                <div>
                                  <span>
                                    {
                                      item.basePrice.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: item.currency
                                      })
                                    }
                                  </span>
                                </div>
                              </div>
                              {
                                materialList[cate.categoryId].length !== index + 1 &&
                                (
                                  <Divider className={"item-list-divider"}/>
                                )
                              }
                            </div>
                          )
                        )
                      }
                      {
                        category.length === cIndex + 1 && (
                          <div style={{height: '2rem'}}/>
                        )
                      }
                    </div>
                  </Tabs.TabPane>
                ))
              }
            </Tabs>
          )
        }
        <Popup
          visible={showDetail.display}
          style={{ height: '100%' }}
          position='bottom'
          closeable
          onClose={()=>{closeShowDetail()}}
        >
          {showDetail.display && (
            <>
              <div className="product-detail-container">
                <div className={"product-detail-name"}>
                  {showDetail.productInfo.materialName}
                </div>
                <img className="w-full shadow-2" src={showDetail.productInfo.image} alt={showDetail.productInfo.name}
                     style={{objectFit: 'cover', height: '10rem', margin: '3.25rem 0 0 0'}}/>
                <div style={{padding: '0 1rem'}}>
                  <p>
                    {showDetail.productInfo.description}
                  </p>
                  <Divider/>
                  {
                    addOnList.map( (header, index) => {
                      return (
                        <div key={header.materialHeaderId}>
                          <Divider contentPosition="left" style={{marginBottom: '0.5rem'}}>
                            <div className="inline-flex align-items-center">
                              {/*<i className="pi pi-user mr-2"></i>*/}
                              <b>{header.headerName}</b>
                            </div>
                          </Divider>
                          {
                            (header.materialItems.length > 0) ? (
                              <div>
                                <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '1em', height: '1em', marginBottom: '0.75rem'}}>
                                  <div style={{color: 'grey'}}>
                                    Select up to {header.maximum}
                                  </div>
                                  {
                                    header.required >= 1 ?
                                      (
                                        <Badge content="Required" color="#87d068" style={{padding: '0 0.5rem'}}/>
                                      ) :
                                      (
                                        <Badge content="Optional" color="#3f45ff" style={{padding: '0 0.5rem'}}/>
                                      )
                                  }
                                </div>
                                <Form layout='vertical'>
                                  <Form.Item name='selection'>
                                    <Selector
                                      style={{'--rv-selector-border-radius': '2rem'}}
                                      options={header.materialItems}
                                      showCheckMark={false}
                                      onChange={(arr, extend) => changeSelection(arr, extend, header, addOnList, index)}
                                      multiple={header.maximum > 1}
                                    />
                                  </Form.Item>
                                </Form>
                              </div>
                            ) : (
                              <div>
                                No data found
                              </div>
                            )
                          }
                        </div>
                      )
                    })
                  }
                  <div style={{marginBottom: '4rem'}}>
                    <Divider contentPosition="center" style={{marginBottom: '1rem'}}>
                      <div className="inline-flex align-items-center">
                        {/*<i className="pi pi-user mr-2"></i>*/}
                        <b>Quantity</b>
                      </div>
                    </Divider>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <Stepper
                        min={1}
                        value={quantity}
                        onChange={v => setQuantity(v)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"product-add-to-box"}>
                <div className={"product-add-to"}>
                  <div className={"product-total"}>
                    {/*  Total: ${(price * quantity).toFixed(2)}*/}
                    {!addLoading && (
                      <>
                        Total: {
                          (price * quantity).toLocaleString('en-US', {
                            style: 'currency',
                            currency: showDetail.productInfo.currency
                          })
                        }
                      </>
                    )}
                  </div>
                  <div className={"product-add-button"}>
                    <Button label="Add" type="info" round
                            style={{width: '100%'}}
                            disabled={addLoading}
                            onClick={()=>{
                              addProduct()
                            }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Popup>
      </div>
      <Overlay
        visible={showError}
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '1000'
        }}
      >
        <div style={{
          width: '18rem', height: '6rem', backgroundColor: '#fff',
          borderRadius: 4, display: 'flex', alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div>
            Table not found, please scan again.
          </div>
        </div>
      </Overlay>
    </div>
  )

}
export default Table;
