import request, {url} from "../Api/request";

export function sendOrderToOMS(data) {
  return request(
    {
      url:'/oms/webSocket/sendOrderToOMS',
      method: 'post',
      data: data
    })
}
