import logo from "../../Assets/img/logo_white.png";
import {BiBell} from "react-icons/bi";
import {useState} from "react";
import { Popup } from 'react-vant';

export default function Header(props){

  const [showNotice, setShowNotice] = useState(true)
  const description = `Happy Hour (14:00 - 18:00): Each customer is kindly requested to order at least 1 item from the menu.\n
    Night Time Charge (18:00 to 00:00): Each customer need to either order 1 item from the menu plus $3.00 board game fee, or opt to order 2 menu items without incurring the board game fee.`

  return (
    <>
      {/*Restaurant Name */}
      <div className={'nav-title-container'}>
        <div>
          <div className={"nav-title"} onClick={()=>{setShowNotice(true)}}>
            <img src={logo} style={{height: '2.25rem', margin: '0 1rem'}} alt={''}/>
            <span>Starry Night</span>
            <BiBell style={{marginLeft: '0.3rem'}}/>
          </div>
        </div>
        <div className={"nav-table"}>
          Table: {props.name}
        </div>
      </div>
      <Popup
        visible={showNotice}
        style={{ height: '30%' }}
        position='bottom'
        round
        onClose={()=>setShowNotice(false)}
        title={"Notice"}
      >
        <div style={{padding: '0rem 1.5rem', fontSize: "0.8rem", color: "#666666"}}>
          {
            description.split('\n').map((item, key) => {
              return (
                <div key={key} style={{margin: '0.5rem 0'}}>
                  {item}
                </div>
              )
            })
          }
        </div>
      </Popup>
    </>
  )

}
