import request, {url} from "../Api/request";

export function getCategory(params) {
  return request(
    {
      url:'/oms/menu/getCategory',
      method: 'get',
      params: params
    })
}

export function getMaterialWithCategory(params) {
  return request(
    {
      url:'/oms/menu/getMaterialWithCategory',
      method: 'get',
      params: params
    })
}

export function getAddOnByMaterial(params) {
  return request(
    {
      url:'/oms/management/getAddOnByMaterial',
      method: 'get',
      params: params
    })
}

export function getTableById(params) {
  return request(
    {
      url: '/oms/management/getTableById',
      method: 'get',
      params: params
    }
  )
}
