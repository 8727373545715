import {Badge, Button, Dialog, Divider, Image, Overlay, Stepper, Tag} from 'react-vant'
import './Table.scss'
import {Cart} from "@react-vant/icons";
import {useEffect, useState} from "react";

function CartButton(props) {


  useEffect(()=>{
    if(props.visible) {
      calcSubtotal(props.data)
      // console.log(props.data)
    }
  },[props.visible])

  const calcSubtotal = (items) => {
    let price = 0.00
    items.forEach(item => {
      price += item.price * item.quantity
    })
    props.setSubtotal(Number(price).toFixed(2))
  }

  return (
    <Overlay
      visible={props.visible}
      onClick={()=>{props.setVisible(false)}}
      className={'table-cart'}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1000'
      }}
    >
      <div className={'table-cart-container'}>
        <div className={'table-cart-title'}>
          Order information
          <Divider style={{margin: '0.25rem 0.25rem 0 0.25rem'}}/>
        </div>
        <div className={"table-cart-content"}>
          {
            props.data.map((item, index) => (
              <div className={"table-cart-list"} key={'index-' + index + '-' + item.materialName}>
                  <div className={"cart-list-item"}>
                    <Image fit={'cover'} width={50} height={50} src={item.image} round />
                    <div style={{padding: '0 0.75rem', flex: 1}}>
                      <div className={"cart-list-text"}>
                        <div>
                          {item.materialName}
                          {/*(X{item.quantity})*/}
                        </div>
                        <div className={"item-list-desc"}>
                          {
                            item.selections.map((sel,index) => (
                              <Tag key={sel.materialItemId + '-' + index} type="primary"
                                   style={{margin: '0 0.25rem 0.25rem 0'}}
                                   plain
                              >
                                {sel.label}
                              </Tag>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 0.75rem'}}>
                    <span style={{display: "flex", alignItems: 'flex-end'}}>
                      {
                        (item.price * item.quantity).toLocaleString('en-US', {
                          style: 'currency',
                          currency: item.currency
                        })
                      }
                    </span>
                    <Stepper
                      min={0}
                      value={item.quantity}
                      onMinus={async (e,v)=>{
                        if (v <= 0) {
                          try {
                            await Dialog.confirm({
                              title: 'Deletion',
                              message: item.materialName + ' will be deleted',
                              confirmButtonText: 'Confirm',
                              cancelButtonText: 'Cancel',
                              onConfirm: () => {
                                let data = JSON.parse(JSON.stringify(props.data))
                                data.splice(index, 1)
                                props.updateData(data)
                                if (data.length <= 0) {
                                  props.setVisible(false)
                                }
                                calcSubtotal(data)
                              },
                            }).then(r => {
                            })
                          } catch (e) {
                            console.log(e)
                          }
                        }
                      }}
                      onChange={v => {
                        if (v > 0) {
                          let data = JSON.parse(JSON.stringify(props.data))
                          data[index].quantity = v
                          props.updateData(data)
                          calcSubtotal(data)
                        }
                      }}
                    />
                  </div>
                  <Divider style={{margin: '0.5rem 0'}}/>
                </div>
            ))
          }
          {
            props.data.length > 0 && (
              <div className={'table-cart-price-content'}>
                <div className={'table-cart-price'}>
                  <div>
                    Cart:
                  </div>
                  <div>
                    {
                      Number(props.subtotal).toLocaleString('en-US', {
                        style: 'currency',
                        currency: props.data[0].currency
                      })
                    }
                  </div>
                </div>
                <Divider/>
                <div className={'table-cart-price'}>
                  <div>
                    HST:
                  </div>
                  <div>
                    {
                      (props.subtotal * 0.13).toLocaleString('en-US', {
                        style: 'currency',
                        currency: props.data[0].currency
                      })}
                  </div>
                </div>
                <Divider style={{margin: '0.75rem 0 '}}/>
                <div className={'table-cart-price'} style={{color: 'black'}}>
                  <div>
                    Total:
                  </div>
                  <div>
                    {
                      (Number(props.subtotal) + Number(props.subtotal) * 0.13).toLocaleString('en-US', {
                        style: 'currency',
                        currency: props.data[0].currency
                      })
                    }
                  </div>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
                  <Button
                    type={"info"} onClick={()=>{props.placeOrder(props.data)}}
                    style={{width: '10rem', fontSize: '1rem'}} round
                    disabled={props.loading}
                  >
                    Place Order
                  </Button>
                </div>
              </div>
            )
          }
          </div>
        </div>
    </Overlay>
  )
}

export default CartButton;
