import './App.scss';
import MainPage from "./Views/MainPage/MainPage";
import {Stars} from "./Utils/Stars";
import {useEffect} from "react";
import {AiFillInstagram} from "react-icons/ai";
import {useRoutes} from 'react-router-dom'
import {routes} from './routes'

function App() {
  return useRoutes(routes)
}

export default App;
