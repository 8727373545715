import './MainPage.scss'
import {useEffect, useRef, useState} from "react";
import {Stars} from "../../Utils/Stars";
import logo from "../../Assets/img/logo_white.png";
import {AiFillInstagram} from "react-icons/ai";
export default function MainPage() {
  const menu1 = require('../../Assets/img/Menu-01.jpg')
  const menu2 = require('../../Assets/img/Menu-02.jpg')
  const menu1Vertical = require('../../Assets/img/Menu-01-vertical.jpg')
  const menu2Vertical = require('../../Assets/img/Menu-02-vertical.jpg')

  const [width, setWidth] = useState(0)

  useEffect(()=>{
    Stars()
    let w = window.innerWidth;
    setWidth(w)

    window.addEventListener('resize', resizeUpdate)

    return () => {
      // 组件销毁时移除监听事件
      window.removeEventListener('resize', resizeUpdate);
    }
  },[])

  const resizeUpdate = (e) => {
    let w = e.target.innerWidth;
    setWidth(w)
  }

  const menu1Display = (width) => {
    if (width > 1200) {
      return (
        <>
          <img src={menu1} width={'90%'}/>
        </>
      )
    } else if (width > 1000) {
      return (
        <>
          <img src={menu1} width={'100%'}/>
        </>
      )
    } else {
      return(
        <>
          <img src={menu1Vertical} width={'100%'}/>
        </>
      )
    }
  }

  const menu2Display = (width) => {
    if (width > 1200) {
      return (
        <>
          <img src={menu2} width={'90%'}/>
        </>
      )
    } else if (width > 1000) {
      return (
        <>
          <img src={menu2} width={'100%'}/>
        </>
      )
    } else {
      return(
        <>
          <img src={menu2Vertical} width={'100%'}/>
        </>
      )
    }
  }


  return (
    <div className="main">
      <canvas id={'stars'}/>
      <div style={{background: 'rgba(46,49,58,0.66)', height: '3rem', width: '100%', zIndex: '9999', color: 'white', display:'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', padding: '0 1.5rem'}}>
          <img src={logo} style={{height: '3rem', marginRight: '0.5rem'}} alt={''}/>
          <span style={{ lineHeight: '3rem', fontSize: '1.5rem'}}>
            Starry Night
          </span>
        </div>
      </div>
      <div className={"main-page"}>
      {width !== 0 && (
        <>
          {menu1Display(width)}
          <div style={{height: '1.5rem'}}/>
          {menu2Display(width)}
        </>
      )}
      </div>
      <div style={{background: 'rgba(46,49,58,0.5)', height: '5.5rem', width: '100%', zIndex: '9999',
        color: '#cdd1b9', display:'flex', justifyContent: 'center', padding: "1rem 0", fontFamily: 'serif'}}>
        <div style={{ padding: '0 1rem', width: '80%' }}>
          <a href={"https://www.instagram.com/starrynight_markham/"} target={'_blank'} style={{textDecoration: 'unset', color: 'unset'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <AiFillInstagram size={"1.5rem"}/>
              @Starrynight_markham
            </div>
          </a>
          <div>
            Tel: (437) 438-8813
          </div>
          <div>
            Address: 10 Fincham Ave Unit 101 Markham, ON, L3P 4C8
          </div>
        </div>
      </div>

    </div>
  )
}
