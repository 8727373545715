// import Layout from "@/pages/Layout";
import {Navigate} from "react-router-dom";
import MainPage from '../Views/MainPage/MainPage.js'
import Table from '../Views/Table/Table.js'

// eslint-disable-next-line
const routes =  [
  // 不需要鉴权的组件Login
  {
    path: "/menu",
    element:
      <MainPage />
  },
  {
    path: "/",
    element: <Navigate to="/menu"/>
  },
  {
    path: "/table",
    element: <Table/>
  },
  // 需要鉴权的组件Layout
  // {
  //   path: "/order/place",
  //   element:
  //     <AuthRoute>
  //       <OrderPage/>
  //     </AuthRoute>
  // },
  // 需要鉴权的组件Layout
  // {
  //   path: "/order/list",
  //   element:
  //     <AuthRoute>
  //       <OrderList/>
  //     </AuthRoute>
  // },
  // // 需要鉴权的组件Layout
  // {
  //   path: "/management/product",
  //   element:
  //     <AuthRoute>
  //       <Product/>
  //     </AuthRoute>
  // }
]

export {
  routes
}
